import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  FileUpload,
  Modal,
  TextInput,
  SelectDropdown,
  Label,
  RequiredFieldIndicator,
  Spinner
} from '@gsa/afp-component-library';
import axios from 'axios';
import { useDocumentManagement } from './documentation-management-provider';

const UploadDocumentationModal = ({ handleClose }) => {
  const { 
    attachmentTypes, 
    getSignedURL, 
    createAttachment, 
    getAttachments, 
    attachments, 
    paginationState, 
    agencyCode,
    setIsLoading,
    isLoading
  } = useDocumentManagement();
  const [categoryError, setCategoryError] = useState(null);
  const [file, setFile] = useState();
  const [documentCategory, setDocumentCategory] = useState('-select-');
  const [note, setNote] = useState('');
  const fileTypes = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/jpg',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'image/png',
    'image/jpeg',
    'image/gif',
    'application/msword'
  ]

  const agencyGuidanceType = attachmentTypes?.getAllAttachmentTypes
    ?.find((o) => o.description === 'Agency guidance');
  const isAgencyGuidanceUploaded = attachments?.rows
    ?.find((o) => o.attachmentTypeId === agencyGuidanceType?.attachmentTypeId)
    ?.attachmentTypeId;

  const generateOptions = (data) => {
    const defaultList = [{ value: '', label: '-select-'}]
    data?.forEach((o) => {
      defaultList.push({ value: o.attachmentTypeId, label: o.description })
    });
    return defaultList;
  }

  useEffect(() => {
    if (isAgencyGuidanceUploaded && documentCategory === isAgencyGuidanceUploaded) {
      setCategoryError('Select a different category')
    }
  }, [documentCategory])

  const handleSave = async () => {
    if (documentCategory === '-select-' || documentCategory === '') {
      setCategoryError('Select category')
      return;
    }

    if (file) {
      setIsLoading(true);
      const fileLocation = `documentation_management/${agencyCode}/${new Date().getFullYear()}/${documentCategory}/${file.name}`;
      const reponse = await getSignedURL({
        variables: {
           input: {
            name: file.name,
            description: note,
            docStore: window.AFP_CONFIG.attachment_bucket,
            fileLocation,
            size: file.size,
            fileMimeType: file.type,
            status: 0,
            type: file.type,
          }
        }
      });

      await axios.put(
        reponse?.data?.generateSignedUrlForDocumentation?.signedUrl,
        file,
        { headers: {'Content-Type': file.type} }
      );

      await createAttachment({
        variables: {
          input: {
            contentURL: reponse?.data?.generateSignedUrlForDocumentation?.signedUrl,
            description: note,
            linkedEntities: {agencyCode},
            attachmentTypeId: documentCategory,
            metadataId: reponse?.data?.generateSignedUrlForDocumentation?.metadataId,
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: new Date().getTime(),
          }
        }
      });
      getAttachments({
        variables: {
          filter: { agencyCode },
          ...paginationState,
          order: [['name', 'ASC']],
        }
      });
      setIsLoading(false);
      handleClose();
    }
  };

  const checkFileValidation = (input) => {
    const sizeVal = (input?.size / (1024*1024)).toFixed(2);
    if(sizeVal < 10 && fileTypes.includes(input?.type)){
      return false
    }
    return true;
  };

  return (
    <div className='afp-modal-overlay upload-modal'>
      {isLoading ? 
        <Spinner size="large" className="margin-y-8" /> : (
          <Modal
            title={<h2>Upload new file</h2>}
            onClose={() => { handleClose() }}
            variant='large'
            actions={
              <div>
                <Button
                  type='button'
                  variant='unstyled'
                  data-testid='upload-cancel-button'
                  onClick={() => { handleClose() }}
                  label='Cancel'
                />
                <Button
                  type='button'
                  variant='primary'
                  disabled={categoryError || checkFileValidation(file)}
                  data-testid='upload-submit-button'
                  onClick={() => handleSave()}
                  label='Save'
                />
              </div>
            }
          >

            <div className='top-padding'>
              <Alert type='warning' className='alert-msg'>
                Help prevent a privacy incident by ensuring that any supporting
                document uploaded here does not contain <strong className='pii-label'>personally identifiable information</strong> (PII).
              </Alert>
              <div role='button' tabIndex='0'>
                <FileUpload
                  acceptableFiles={fileTypes}
                  fileSizeLimit='10'
                  data-testid='document-upload-file'
                  acceptableFilesLabel='Accepts .pdf, .xls, .doc, .jpg, .gif, and .png files'
                  onChange={(e) => {
                      setFile(e)
                  }}
                />
              </div>

              <div className='top-padding'>
                {categoryError === 'Select a different category' &&
                  <Alert type='error' className='alert-msg'>
                    Agency guidance is already on file. Select a different
                    category or remove the existing guidance, then replace it with a new one.
                  </Alert>}

                <SelectDropdown
                  data-testid='document-category-select'
                  name='documentCategory'
                  label={<><strong>Category</strong><RequiredFieldIndicator /> </>}
                  value={documentCategory}
                  onChange={(e) => {
                    setDocumentCategory(e.target.value)
                    if (e.target.value !== '') {
                      setCategoryError(null)
                    }
                  }}
                  options={generateOptions(attachmentTypes?.getAllAttachmentTypes)}
                  errorMessage={categoryError}
                />
              </div>

              <div className='file-description'>
                <TextInput
                  type='textarea'
                  label={<Label><strong>Description</strong></Label>}
                  aria-label='note textarea'
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  data-testid='attachment-note'
                  maxLength={100}
                  help='A few words to help identify this document'
                  hint={`${100} characters allowed`}
                />
              </div>
            </div>
          </Modal>
        )}
    </div>
  );
};

UploadDocumentationModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default UploadDocumentationModal;
